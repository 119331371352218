<template>
<div class="navbar-custom">
    <ul class="list-unstyled topnav-menu float-right mb-0">
        <li class="dropdown notification-list">
            <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                <span class="pro-user-name ml-1">
                    Morgan K <i class="mdi mdi-chevron-down"></i> 
                </span>
            </a>
        </li>
        <li class="dropdown notification-list">
            <a href="javascript:void(0);" @click="logout" class="nav-link right-bar-toggle waves-effect waves-light">
                Logout
            </a>
        </li>
    </ul>
</div>
<div class="left-side-menu">
    <div class="slimscroll-menu">
        <a href="index.html" class="logo text-center mb-4">
            <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="20">
            </span>
            <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="24">
            </span>
        </a>
        <div id="sidebar-menu">
            <ul class="metismenu" id="side-menu">
                <li class="menu-title">Hostpleng</li>
                <li>
                    <a href="/hostpleng/users">
                        <i class="fe-airplay"></i>
                        <span> User Lists </span>
                    </a>
                    <a href="/hostpleng/blog">
                        <i class="fe-airplay"></i>
                        <span> Blog Writer</span>
                    </a>
                </li>
                <li class="menu-title">DJ.in.th</li>
                <li>
                    <a href="/dj/client">
                        <i class="fe-airplay"></i>
                        <span> Clients</span>
                    </a>
                </li>
                <li>
                    <a href="/dj/enroll">
                        <i class="fe-airplay"></i>
                        <span> Enroll</span>
                    </a>
                </li>
                <li>
                    <a href="/dj/application">
                        <i class="fe-airplay"></i>
                        <span> Applications</span>
                    </a>
                </li>
                <li>
                    <a href="/dj/billing">
                        <i class="fe-airplay"></i>
                        <span> Billing</span>
                    </a>
                </li>
                <li>
                    <a href="/dj/chatlogs">
                        <i class="fe-airplay"></i>
                        <span> Chat logs</span>
                    </a>
                </li>

            </ul>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
</template>