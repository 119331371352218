import firebase from "firebase/app";
import $ from 'jquery'
import Swal from 'sweetalert2'
const myMixin = {
    methods: {
        loadshow(status, text, ele = false) {
            if (status == true) {
                let options = {
                    spinner: "pulsar",
                    color: "grey",
                    text: text,
                    fontSize: "1rem",
                    textPosition: "bottom",
                    textMargin: "0.5rem",
                    minSize: "50px",
                    maxSize: "100px",
                }
                if (ele)
                    $(ele).busyLoad("show", options)
                else
                    $.busyLoadFull("show", options);
            }
            else {
                if (ele)
                    $("*").busyLoad("hide")
                else
                    $.busyLoadFull("hide");
            }
        },
        g_errorMsg(message, timer = 10000) {
            Swal.fire({ icon: 'warning', title: 'เกิดข้อผิดพลาด', text: message, showConfirmButton: false, timer: timer });
        },
        conflicting() {
            console.log('from mixin')
        },
        logout(){
            firebase.auth().signOut().then(function() {
                window.location.reload()
            })
        }
    }
}
export default myMixin