import { createApp } from 'vue'
import App from './App.vue'
import { createRouter,createWebHistory } from 'vue-router'
import firebase from "firebase/app";
import myMixin from './ecosystem/globalfunction'


import index from './index.vue'
const routes = [
  { path: '/', name: 'index', component: index },
  { path: '/login', name: 'login', component: () => import('./common/login') },
  { path: '/hostpleng/users', name: 'hostplengusers', component: () => import('./hostpleng/users') },
  { path: '/hostpleng/blog', name: 'blog', component: () => import('./hostpleng/blog') },
  { path: '/dj/enroll', name: 'djenroll', component: () => import('./dj/enroll') },
  { path: '/dj/application', name: 'djapplication', component: () => import('./dj/application') },
  { path: '/dj/billing', name: 'djbilling', component: () => import('./dj/billing') },
  { path: '/dj/client', name: 'djclient', component: () => import('./dj/client') },
  { path: '/dj/chatlogs', name: 'djchatlogs', component: () => import('./dj/chatlogs') },
  { path: '/test', name: 'test', component: () => import('./test') },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})

var app = createApp(App)
app.use(router)
app.mixin(myMixin)
app.mount('#app')
router.beforeEach(async (to, from, next) =>
{
  document.title = to.meta.title
  let databaseurl = "hostpleng"
  if(to.path.match(/^\/dj/))
    databaseurl = "djinth"



  const firebaseConfig = {
    apiKey: "AIzaSyAJjKcYsNzIrJEPkK9R0PARBDjmEZrBWoY",
    authDomain: "vipzeapp.firebaseapp.com",
    databaseURL: `https://${databaseurl}.firebaseio.com`,
    projectId: "vipzeapp",
    storageBucket: "vipzeapp.appspot.com",
    messagingSenderId: "148784633066",
    appId: "1:148784633066:web:eba867d0aa43f5dd03f393",
    measurementId: "G-TBWECPGD9G"
  };
  firebase.initializeApp(firebaseConfig)
  next()
})