<template>
<div id="wrapper">
  <app-header />
    <div class="content-page p-0">
        <div class="content ">
            <div class="container p-0">
                <router-view></router-view>     
            </div>
        </div>
        <app-footer />
    </div>
</div>
<div class="rightbar-overlay"></div>
</template>


<style src="@/assets/css/bootstrap.min.css"></style>
<style src="@/assets/css/icons.css"></style>
<style src="@/assets/css/app.min.css"></style>

<script>
import header from '@/common/header'
import footer from '@/common/footer'
// import 'bootstrap/dist/js/bootstrap.min.js'
// import $ from 'jquery'


export default
{
    components: {
    'app-header': header,
    'app-footer' : footer
    },
    mounted () {
     

    }
}
</script>